import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const ViewMore = styled(Link)`
  color: #f7fafc;
  background: #22543d;
  font-size: 0.8rem;
  line-height: 1;
  padding-right: 1rem;
  align-items: center;
  margin-left: 2rem;
  display: flex;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 0.6rem;
  text-decoration: none;
  font-weight: 600;
  &:hover {
    transition: 0.25s;
    background: #615b5b;
    border-radius: 4px;
  }
  @media (max-width: 640px) {
    text-align: center;
    margin-right: 0.5rem;
  }
  @media (max-width: 340px) {
    font-size: 0.8rem;
  }
`

function ChristmasBanner() {
  return (
    <div
      css={css`
        background: #ff4a4a;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem calc((100vw - 1050px) / 2);
        height: 3rem;
        align-items: center;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        filter: alpha(opacity=90);
        -moz-opacity: 0.9;
        -khtml-opacity: 0.9;
        opacity: 0.9;
        position: sticky;
        top: 0;
        z-index: 999;
        @media (max-width: 640px) {
          height: 3.5rem;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          @media (max-width: 1050px) {
            margin-left: 1.5rem;
          }
        `}
      >
        <Link
          to="/christmas"
          css={css`
            color: #f7fafc;
            font-weight: 500;
            font-size: 1.2rem;
            text-decoration: none;
            @media (max-width: 640px) {
              font-size: 1rem;
            }
          `}
        >
          Christmas at Cross Church // December 6th - 24th
        </Link>
        <ViewMore to="/christmas">View Times</ViewMore>
      </div>
    </div>
  )
}

export default ChristmasBanner
